@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.Home-Container{
    padding: 0;
    margin:0;
    background-color: #fce7e5;
    
}
.row{
    --bs-gutter-x:0;
    --bs-gutter-y:0;
}
.Contenedor-Home-Banner1{
    --bs-gutter-x:0;
}
.Home-Banner1-pc{
    width: 100%;
}
.Home-Banner1-mobile{
    width: 100%;
    display: none;
}
.Home-Container .Row-container-servicios{
    width: 100%;
    background-image: url('./images/fondo-servicios-home.png');
    background-size: 100%;
    margin-left: 0;
}
.Home-Container .Col-Titulo-Servicios{
    margin: 3% 20% 3% 20%;
    text-align: center;
    background-color: #125d51;
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    border-radius: 70px;
    border-color: #f7b0aa;
    border-width: 10px;
    border-style: solid;
}
.Home-Container .Col-Titulo-Servicios h2{
    margin: 2%;
    font-size: clamp(1.2em, 2.3vw, 2.6em);
    
}
.Home-Container .Col-Servicios{
    margin:0;
    height: 210px;
}
.Home-Container .Forma1{
    width: 55%;
    height: 85%;
    margin: auto 2% auto auto;
    background-image: url("./images/psicologo.webp");
    /* background-color: #125d51; */
    background-size: 100%;
    border-top-left-radius: 80px ;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius:80px ;
    border: 10px;
    border-color: #f7b0aa;
    border-style: solid;
    transition: 0.5s;
}
.Home-Container .color-layer1{
    width: 100%;
    height: 100%;
    background-color: #125d51d8;
    background-size: 100%;
    border-top-left-radius: 70px ;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius:70px ;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.Home-Container .Forma1:hover{
    transform: scale(1.1) ;
    transition: 0.5s;
}
.Home-Container .Forma1:hover .Forma-Texto{
    transition: 0.5s;
    opacity: 0;
}
.Home-Container .Forma1:hover .Forma-Texto-Hover{
    transition: 0.5s;
    opacity: 1;
}
.Home-Container .Forma2{
    width: 55%;
    height: 85%;
    margin: auto auto auto 2%;
    background-image: url("./images/psicopedagogo.jpg");
    background-size: 100%;
    border-bottom-left-radius: 80px ;
    border-bottom-right-radius: 80px;
    border-top-right-radius:80px ;
    border: 10px;
    border-color: #f7b0aa;
    border-style: solid;
    transition: 0.5s;
}
.Home-Container .color-layer2{
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #125d51d8;
    border-bottom-left-radius: 70px ;
    border-bottom-right-radius: 70px;
    border-top-right-radius:70px ;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.Home-Container .Forma2:hover{
    transform: scale(1.1) ;
    transition: 0.5s;
}
.Home-Container .Forma2:hover .Forma-Texto{
    transition: 0.5s;
    opacity: 0;
}
.Home-Container .Forma2:hover .Forma-Texto-Hover{
    transition: 0.5s;
    opacity: 1;
}
.Home-Container .Forma3{
    width: 55%;
    height: 85%;
    margin: auto 2% auto auto;
    background-image: url("./images/terapeuta-ocupacional.jpg");
    background-size: 100%;
    border-top-left-radius: 80px ;
    border-top-right-radius: 80px;
    border-bottom-left-radius:80px ;
    border: 10px;
    border-color: #f7b0aa;
    border-style: solid;
    transition: 0.5s;
}
.Home-Container .color-layer3{
    width: 100%;
    height: 100%;
    background-color: #125d51d8;
    border-top-left-radius: 70px ;
    border-top-right-radius: 70px;
    border-bottom-left-radius:70px ;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.Home-Container .Forma3:hover{
    transform: scale(1.1) ;
    transition: 0.5s;
}
.Home-Container .Forma3:hover .Forma-Texto{
    transition: 0.5s;
    opacity: 0;
}
.Home-Container .Forma3:hover .Forma-Texto-Hover{
    transition: 0.5s;
    opacity: 1;
}
.Home-Container .Forma4{
    width: 55%;
    height: 85%;
    margin: auto auto auto 2%;
    background-image: url("./images/coaching.jpg");
    background-size: 100%;
    border-top-left-radius: 80px ;
    border-bottom-right-radius: 80px;
    border-top-right-radius:80px ;
    border: 10px;
    border-color: #f7b0aa;
    border-style: solid;
    transition: 0.5s;
}
.Home-Container .color-layer4{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #125d51d8;
    border-top-left-radius: 70px ;
    border-bottom-right-radius: 70px;
    border-top-right-radius:70px ;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.Home-Container .Forma4:hover{
    transform: scale(1.1) ;
    transition: 0.5s;
}
.Home-Container .Forma4:hover .Forma-Texto{
    transition: 0.5s;
    opacity: 0;
}
.Home-Container .Forma4:hover .Forma-Texto-Hover{
    transition: 0.5s;
    opacity: 1;
}
.Home-Container .Forma5{
    width: 55%;
    height: 85%;
    margin: auto auto auto auto;
    background-image: url("./images/psicologia-organizacional.jpg");
    background-size: 100%;
    border-top-left-radius: 80px ;
    border-top-right-radius: 80px;
    border-bottom-left-radius: 80px ;
    border-bottom-right-radius: 80px;
    border: 10px;
    border-color: #f7b0aa;
    border-style: solid;
    transition: 0.5s;
}
.Home-Container .color-layer5{
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #125d51d8;
    border-top-left-radius: 70px ;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px ;
    border-bottom-right-radius: 70px;
    text-align: center;
    justify-content: center;
    align-items: center;
}
.Home-Container .Forma5:hover{
    transform: scale(1.1) ;
    transition: 0.5s;
}
.Home-Container .Forma5:hover .Forma-Texto{
    transition: 0.5s;
    opacity: 0;
}
.Home-Container .Forma5:hover .Forma-Texto-Hover{
    transition: 0.5s;
    opacity: 1;
}
.Home-Container .Forma-Texto{
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    font-size: 1.5em;
    opacity: 1;
}
.Home-Container .Forma-Texto-Hover{
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    font-size: 1.5em;
    position: absolute;
    opacity: 0;
    text-decoration: underline;
}
.Home-Container .row-preguntasydocumentos{
    width: 100%;
    height: fit-content;
    padding-top: 5%;
}
.Home-Container .item-container{
    background-color: #125d51; 
    border-radius: 0px 35px 35px 0px ;
    border-width: 6px 6px 6px 0px;
    border-color: #f7b0aa;
    border-style: solid;
    margin: 5% 5% 5% 0%;
    padding: 0px;
}
.Home-Container .pregunta-container{
    margin:0% 0% 0% 0%;
    background-color: #125d5100;
    color: #fce7e5;
}
.Home-Container .titulo-pregunta{
    margin-left:27%;
    background-color: #125d5100;
    color: #fce7e5;
}
.Home-Container .respuesta-pregunta{
    margin-left: 30%;
    background-color: #125d5100;
    color: #fce7e5;
    font-family: CaviarDreams-Regular;
}
.Home-Container .accordion{
    --bs-accordion-border-color: 0 0 0 0 0%;
    --bs-accordion-active-bg: #125d5100;
    --bs-accordion-border-radius: 0px 25px 25px 0px;
    --bs-accordion-btn-focus-box-shadow: #f7b0aa67;
    --bs-accordion-active-color: #fce7e5;
}
.Home-Container .accordion-button {
    background-color: #125d5100;
    width: 100%;
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    font-size: 1.2rem;
}
.Home-Container .accordion-button::after {
    background-color: #f7b0aa;
    border-radius: 50%
}
.Home-Container .col-documentos{
    padding: 0% 5% 0% 5%; 
    display: flex;
    justify-content: center;
    text-align: center;
}
.Home-Container .container-documentos{
    background-color: #74B795;
    height: fit-content;
    border-radius: 50px;
    border-width: 10px;
    border-color: #f7b0aa;
    border-style: solid;
    padding: 3%;
    
}
.Home-Container .container-titulo{
    font-family: CaviarDreams-Bold;
    color: #125d51;
    padding-bottom: 3%;
}
.Home-Container .row-container-descargable{
    margin: auto;
    display: flex;
}
.Home-Container .row-container-descargable-item{
    font-family: CaviarDreams-Bold;
    color: #125d51;
    margin: 3% 0%;
    text-align: left;
    align-items: center;
}
.Home-Container .row-container-descargable-item a{
    font-family: CaviarDreams-Bold;
    color: #125d51;
}

@media screen and (max-width: 480px) {
    .Home-Container .Col-Titulo-Servicios{
        margin: 3% 0% 3% 0%;
    }
    .Home-Container .a-servicios{
        width: 93%;
        height: 93%;
    }
    .Home-Container .Col-Servicios{
        margin:0;
        height: 130px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .Home-Container .Forma1{
        width: 95%;
        height: 95%;
        margin: auto;
        border-top-right-radius:80px ;
    }
    .Home-Container .color-layer1{
        border-top-right-radius: 70px ;
    }
    .Home-Container .Forma2{
        width: 95%;
        height: 95%;
        margin: auto;
        border-top-left-radius:80px ;
    }
    .Home-Container .color-layer2{
        border-top-left-radius: 70px ;
    }
    .Home-Container .Forma3{
        width: 95%;
        height: 95%;
        margin: auto;
        border-bottom-right-radius:80px ;
    }
    .Home-Container .color-layer3{
        border-bottom-right-radius: 70px ;
    }
    .Home-Container .Forma4{
        width: 95%;
        height: 95%;
        margin: auto;
        border-bottom-left-radius:80px ;
    }
    .Home-Container .color-layer4{
        border-bottom-left-radius: 70px ;
    }
    .Home-Container .Forma-Texto{
        color: #fce7e5;
        font-family: CaviarDreams-Bold;
        font-size: 1.25em;
        opacity: 1;
    }
    .Home-Container .Forma-Texto-Hover{
        color: #fce7e5;
        font-family: CaviarDreams-Bold;
        font-size: 1.25em;
        position: absolute;
        opacity: 0;
        text-decoration: underline;
    }
    .Home-Container .titulo-pregunta{
        margin-left:2%;
    }
    .Home-Container .respuesta-pregunta{
        margin-left: 3%;
    }
    .Home-Container .Col-Titulo-Servicios{
        margin: 3% 5% 3% 5%;
    
    }
    .Home-Banner1-mobile{
        display: block;
    }
    .Home-Banner1-pc{
        display: none;
    }
    .row-container-descargable-item{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width: 991px) {
    .Home-Container .Col-Titulo-Servicios{
        margin: 3% 5% 3% 5%;
    }
    .Home-Container .a-servicios{
        width: 70%;
        height: 93%;
    }
    .Home-Container .Col-Servicios{
        margin:0;
        height: 130px;
        display:flex;
        justify-content: center;
        align-items: center;
    }
    .Home-Container .Forma1{
        width: 95%;
        height: 95%;
        margin: auto;
        border-top-right-radius:80px ;
    }
    .Home-Container .color-layer1{
        border-top-right-radius: 70px ;
    }
    .Home-Container .Forma2{
        width: 95%;
        height: 95%;
        margin: auto;
        border-top-left-radius:80px ;
    }
    .Home-Container .color-layer2{
        border-top-left-radius: 70px ;
    }
    .Home-Container .Forma3{
        width: 95%;
        height: 95%;
        margin: auto;
        border-bottom-right-radius:80px ;
    }
    .Home-Container .color-layer3{
        border-bottom-right-radius: 70px ;
    }
    .Home-Container .Forma4{
        width: 95%;
        height: 95%;
        margin: auto;
        border-bottom-left-radius:80px ;
    }
    .Home-Container .color-layer4{
        border-bottom-left-radius: 70px ;
    }
    .Home-Container .Forma-Texto{
        font-size: 1.25em;
    }
    .Home-Container .Forma-Texto-Hover{
        font-size: 1.25em;
    }
    .Home-Container .titulo-pregunta{
        margin-left:8%;
    }
    .Home-Container .respuesta-pregunta{
        margin-left: 10%;
    }
}