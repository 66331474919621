@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.container-contacto{
    padding: 0 !important;
    background-image: url("../Servicios/images/fondoservicios.png");
}
.row-contacto-banner{
    padding-bottom: 1.5%;
}
.img-contacto-banner-pc{
    /* border-top: #f7b0aa; */
    padding: 0% !important;
    border-bottom: #f7b0aa;
    border-top-width: 0px;
    border-bottom-width: 15px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
}
.img-contacto-banner-mobile{
    /* border-top: #f7b0aa; */
    padding: 0% !important;
    border-bottom: #f7b0aa;
    border-top-width: 0px;
    border-bottom-width: 15px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-style: solid;
    display: none;
}
.row-contacto-titulo{
    padding-bottom: 2%;
    font-family: CaviarDreams-Bold;
    color: #125d51;
}
.col-contacto-titulo{
    text-align: center;
}
.row-contacto-cuerpo{
    padding-left: 10%;
    padding-right: 10%;
}
.col-contacto-cuerpo-contenido{
    text-align: center;
    color: #125d51;
    font-family: CaviarDreams-Bold;
    margin: auto;
}
.row-contacto-cuerpo-contenido{
    margin: 8%;
}
@media screen and (max-width: 480px) {
    .img-contacto-banner-pc{
        display: none;
    }
    .img-contacto-banner-mobile{
        display: block;
    }
}