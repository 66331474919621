@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.card-anchor{
    text-decoration: none;
}
.card-home{
    transition: 0.5s;
}
.card-home:hover{
    text-align: center;
    transition: 0.5s;
    transform: scale(1.1);
}
.card-anchor .card-img-top{
    border-radius: 50%;
    border: 10px;
    border-style: solid;
    border-color: #f7b0aa;
    margin:auto;
    width: calc(50%);
    
}
.card-home .nombre {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    color: #fce7e5;
    font-family: CaviarDreams-Regular;
}
.card-home .cargo {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
}
.card-home .card-text{
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    color: #fce7e5;
    font-family: CaviarDreams-Regular;
}