@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.card{
    text-align: center;
    height: 80%;
}
.image{
    border-radius: 50%;
    border: 6px;
    border-style: solid;
    border-color: #f7b0aa;
    margin:auto;
    width: 100%;
    height: 100%;
    position: relative;
    animation: rotar2 20s infinite linear;
}
.boton-imagen{
    width: fit-content;
    height: fit-content;
    margin: auto;
    border-radius: 100%;
    border-width: 0.4rem;
    border-style: dashed;
    border-color:#74B795;
    /* background-image: radial-gradient(#74B795 20, transparent 60,
    #74B795 100,#74B795 140 ,
    transparent 180,transparent 220,
    #74B795 260, #74B795 300
    transparent 340,transparent 360); */
    padding: 0;
    animation: rotar 20s infinite linear; 
}
@keyframes rotar {
    from{
        transform: rotate(0dg);
    }
    to{
        transform: rotate(360deg);
    }
}
@keyframes rotar2 {
    from{
        transform: rotate(0dg);
    }
    to{
        transform: rotate(-360deg);
    }
}
.modal-video .modal-dialog{
    display: flex;
    width: 100%;
    align-content: center;
    justify-content: center;
}
.modal-video .modal-dialog .modal-content{
    width: fit-content;
}
.nombre {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    color: #fce7e5;
    font-family: CaviarDreams-Regular;
}
.cargo {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
}
.card-text{
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    color: #fce7e5;
    font-family: CaviarDreams-Regular;
}
.container-button{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10%;
}
.agendar-button{
    height: 4rem;
    width: 70%;
    background-color: #fce7e5;
    font-family: CaviarDreams-Bold;
    font-size: 1.2rem;
    color: #125d51;
    border-radius: 5rem;
    border-style: solid;
    border-color: #f7b0aa;
    border-width: 0.3rem;
    --bs-btn-hover-color: #125d51;
    --bs-btn-hover-bg: #f7b0aa;
    --bs-btn-hover-border-color: #fce7e5;
}