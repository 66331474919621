@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.container-profesionales{
    padding-bottom: 10%;
    background-image: url('../Blog/Images/FOndo.png') ;
    background-size: 100%;
}
.row-titulo{
    text-align: center;
    padding: 3% 0% 0% 0%;
    font-family: CaviarDreams-Bold;
    color: #125d51;
}
.row-cards-container{
    padding-right: 8%;
    padding-left: 8%;
    padding-bottom: 0%;
}
.pro-card{
    display: flex;
    flex-direction: column;
    background-color: #125d51;
    width: 80%;
    height: 90%;
    margin: 10%;
    padding-top: 5%;
    border-radius: 4rem;
    align-items: center;
}
.row-control-agendamiento{
    position: relative;
    min-height: 750px;
    width: 100%;
}
.agendar-button{
    display: flex;
    margin: 5%;
    color: #125d51;
    justify-content: center;
    text-align: center;
    align-items: center;
}
@media screen and (max-width: 480px) {
    .row-cards-container{
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 0%;
    }
}
