@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.Contenedor-Header{
    display: flex;
    background-color: #125d51;
    height: 120px;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: center;
    border-bottom:#f7b0aa ;
    border-bottom-width: 10px;
    border-bottom-style: solid;
}
.Contenedor-Header .Logo-Header{
    height: 60%;
    width: auto;
    margin: auto;
}
.Contenedor-Nav{
    height: fit-content;
    width: 100%;
    background-color: #125d51 ;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Contenedor-Nav .nav-botones{
    margin: auto;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
.Contenedor-Nav .nav-toggle-control{
    color: #f7b0aa !important;
    border-color: #f7b0aa;
}
.Contenedor-Nav .nav-toggle-control .navbar-toggler-icon{
    color: #f7b0aa;
    background-image: url("./images/lineas.png");
    
}
.Contenedor-Nav .btn{
    color: #fce7e5;
    font-family: CaviarDreams-Regular;
    font-size: 1.2rem;
    transition: 0.3s;
    width: 100%;
}
.Contenedor-Nav .btn:hover{
    transition: 0.3s;
    transform: scale(1.1);
    color: #f7b0aa;
}