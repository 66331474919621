@font-face {
    font-family: CaviarDreams-Regular; src: url(../../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.form-contenedor{
    min-height: 95%;
}
.contenedor-panel{
    min-height: 85%;
    width: 70%;
    margin: auto;
    background-color: #125d51;
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    padding: 2% 5% 2% 5%;
    border-radius: 30px;
    border-color: #f7b0aa;
    border-style: solid;
    border-width: 10px;
    position: relative;
}
.contenedor-panel .col-calendario{
    display: flex;
}
.contenedor-panel .col-calendario-horas{
    display: flex;
    justify-content: center;
    align-items: center;
}
.contenedor-panel .row-form-group{
    width: 100%;
    height: 75%;
}
.row-form-inputs{
    padding: 0%;
}
.row-form-inputs input{
    width: 90%;
}
.contenedor-panel .titulo-panel{
    height: fit-content;
}
.contenedor-panel .contenedor-boton-siguiente{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.contenedor-panel .contenedor-boton-atras{
    display: flex;
    align-items: flex-end;
}
.contenedor-panel .boton-siguiente{
    width: 120px;
    height: 80px;
    color: #fce7e5;
    background-color: #125d51;
    border-radius: 2rem;
    border-width: 5px;
    border-style: solid;
    border-color: #f7b0aa;
}
.contenedor-panel .boton-siguiente:hover{
    background-color: #f7b0aa;
    color: #125d51;
}
.contenedor-panel .boton-atras{
    width: 120px;
    height: 80px;
    color: #fce7e5;
    background-color: #125d51;
    border-radius: 2rem;
    border-width: 5px;
    border-style: solid;
    border-color: #f7b0aa;
}
.contenedor-panel .boton-atras:hover{
    background-color: #f7b0aa;
    color: #125d51;
}
.contenedor-botones-superiores{
    padding-bottom: 3%;
    padding-top: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.boton-superior{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 10%;
    margin-right: 10%;
    background-color: #125d51;
    color: #fce7e5;
    border-width:7px ;
    border-style: solid;
    border-color: #f7b0aa;
    font-family: CaviarDreams-Bold;
    font-size: x-large;
}
.boton-superior-selected{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 10%;
    margin-right: 10%;
    background-color: #f7b0aa;
    color: #125d51;
    border-width: 7px ;
    border-style: solid;
    border-color: #f7b0aa;
    font-family: CaviarDreams-Bold;
    font-size:x-large;
}
.col-info-confirmacion{
    display: flex;
    justify-content: center;
    align-items: center;
}
/*#region Calendario*/
/* .calendario-localization-provider{
    height: 50%;
}
.MuiDateCalendar-root{
    background-color: #125d51;
    color: #fce7e5 !important;
    
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root{
    width: 100%;
    height: 100%;
}
.MuiPickersCalendarHeader-label{
    color: #fce7e5 ;
    font-family: CaviarDreams-Regular ;
}
.MuiTypography-root{
    color: #fce7e5 !important;
    font-family: CaviarDreams-Regular !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
    color: #fce7e5e4
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
    background-color: #fce7e5 !important;
    border-radius: 10px !important;
    color: #125d51 !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #f7b0aa !important;
    border-radius: 10px;
}
.css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled {
    background-color: transparent;
    color: #fce7e5a9 !important;
}
.css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button.Mui-disabled {
    background-color: transparent;
    color: #fce7e5a9 !important;
}
.css-jgls56-MuiButtonBase-root-MuiPickersDay-root{
    border-radius: 10px !important;
    background-color: #74B795 !important;
}
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
    background-color: #74B795 !important;
    color: rgba(0, 0, 0, 0.38)!important;
} */
/*#endregion Calendario*/

@media screen and (max-width: 767px){
    .contenedor-panel{
        width: 90%;
    }
    .boton-superior{
        margin: 5%;
    }
    .boton-superior-selected{
        margin: 5%;
    }
}
@media screen and (max-width: 1080px){
    .contenedor-panel{
        width: 90%;
    }
    
}