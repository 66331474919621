@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.container-servicios{
    background-image: url('../Blog/Images/FOndo.png') ;
    background-size: 100%;
    padding: 0;
}
.container-titulo-servicios{
    display: flex;
    margin: 0;
    align-items: center;
    text-align: center;
    color: #125d51;
    font-family: CaviarDreams-Bold;
}
.titulo-banner-pc{
    width: 100%;
    border-bottom-color:#f7b0aa;
    border-bottom-style: solid;
    border-bottom-width: 10px;
}
.titulo-banner-mobile{
    display: none;
    width: 100%;
    border-bottom-color:#f7b0aa;
    border-bottom-style: solid;
    border-bottom-width: 10px;
}
.container-cards-servicios{
    width: 100%;
    height: fit-content;
    padding: 3% 12% 0% 12%;
    margin: 0;
}
.container-servicios .card-servicio-pc{
    background-color: #74B795;
    padding: 0% 2% 0% 2%;
    margin-bottom: 5%;
    border-radius: 3.5rem;
}
.container-servicios .card-servicio-mobile{
    background-color: #74B795;
    padding: 0% 2% 0% 2%;
    margin-bottom: 5%;
    border-radius: 3.5rem;
}
.container-servicios .col-card-texto{
    padding: 5%;
    margin: auto;
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
}
.container-servicios .col-card-texto h3{
    margin-bottom: 4%;
}
.container-servicios .col-card-imagen{
    display: flex;
    padding: 3%;
    justify-content: center;
    align-items: center;
}
.container-servicios .img-card{
    width: 100%;
    border-radius: 4rem;
    border-width: .4rem;
    border-style: solid;
    border-color: #fce7e5;
}
.container-servicios .accordion-item{
    border: none;
    background-color: none !important;
}
.container-servicios .card-servicio-mobile{
    --bs-accordion-border-color: 0 0 0 0 0%;
    --bs-accordion-active-bg: #125d5100;
    --bs-accordion-border-radius: 0px 25px 25px 0px;
    --bs-accordion-btn-focus-box-shadow: #f7b0aa67;
    --bs-accordion-active-color: #fce7e5;
}
.container-servicios .accordion {
    display: none;
    --bs-accordion-bg: none;
}
.container-servicios .accordion-button {
    background-color: #125d5100;
    width: 100%;
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    font-size: 1.2rem;
}
.container-servicios .accordion-button::after {
    background-color: #f7b0aa;
    border-radius: 50%;
    display: none;
}
.custom-accordion-button{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}
@media screen and (max-width: 480px) {
    .titulo-banner-pc{
        display: none;
    }
    .titulo-banner-mobile{
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .container-cards-servicios{
        padding: 3% 1% 3% 1%;
    }
}
@media screen and (max-width: 1100px) {
    .container-servicios .accordion {
        display:flex ;
        flex-direction: column;
        --bs-accordion-bg: none;
    }
    .container-servicios .card-servicio-pc{
        display: none   ;
    }
    
}