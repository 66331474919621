@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.blog-detail-container{
    background-image: url("./Images/FOndo.png");
    padding: 0;
    margin: 0;
    background-size: cover;
}

.row-titulo-blog-detail{
    font-family: CaviarDreams-Bold;
    color: #fce7e5;
    height: 30%;
    width: 100vw;
    overflow: hidden;
    border-bottom-width: 10px;
    border-bottom-style: solid;
    border-bottom-color: #f7b0aa;
    background-size:cover;
}
.color-layer-blog-detail{
    width: 100%;
    height: 100%;
    background-color: #125d5197;
    padding-left: 10%;
    padding-bottom: 2%;
    display: flex;
    padding-top: 5%;
    flex-direction: column;
}
.row-body-blog-detail{
    padding: 1% 10% 5% 10%;
    font-family: CaviarDreams-Bold;
    color: #125d51;
}
.btn-blog-detail{
    width:fit-content;
    margin: 1.2%;
    background-color: #125d51;
}.btn-blog-detail :hover{
    background-color: #125d51;
}
.col-text-blog-detail{
    display: flex;
}
.col-text-blog-detail p{
    text-align: justify;
    white-space:pre-line;
}
.img-blog-detail{
    width: 50%;
    padding: 0% 0% 3% 5%;
    float: right;
}
.titulo-banner-blog-item{
    font-size: 1.5em;
}
.fecha-banner-blog-item{
    font-size: .9em;
}
.creador-banner-blog-item{
    font-size: .9em;
}
.titulo-banner-blog-item{
    font-size: 2em;
}
.fecha-banner-blog-item{
    font-size: 1.2em;
}
.creador-banner-blog-item{
    font-size: 1.2em;
}
@media screen and (max-width: 480px) {
    .img-blog-detail{
        width: 100%;
        padding: 0% 0% 3% 0%;
        margin-bottom: 4%;
        margin-top: 3%;    
    }
    .titulo-banner-blog-item{
        padding-right: 5%;
    }
    .row-titulo-blog-detail{
        border-top-width: 10px;
        border-top-style: solid;
        border-top-color: #f7b0aa;
    }
    .titulo-banner-blog-item{
        font-size: 1.5em;
    }
    .fecha-banner-blog-item{
        font-size: .9em;
    }
    .creador-banner-blog-item{
        font-size: .9em;
    }
}