.footer-container{
    padding: 0%;
    margin: 0%;
    background-color: #125d51;
    border-top: #f7b0aa;
    border-top-width: 10px;
    border-top-style: solid;
}
.logo-footer{
    padding: 20% 2% 2% 10%;
    width: 70%;
    height: auto;
}
.nav-footer-container{
    padding: 3% 3% 3% 0%;
    color: #fce7e5;
}
.direccion-footer{
    padding: 3% 3% 3% 0%;
    color: #fce7e5;

}
.redes-footer{
    padding: 3% 3% 3% 0%;
    color: #fce7e5;
}
.footer-container a:hover {
    text-decoration: underline !important;
}
@media screen and (max-width: 576px) {
    .nav-footer-container{
        padding: 3% 3% 3% 10%;
        color: #fce7e5;
    }
    .direccion-footer{
        padding: 3% 3% 3% 10%;
        color: #fce7e5;
    
    }
    .redes-footer{
        padding: 3% 3% 3% 10%;
        color: #fce7e5;
    }
}