@font-face {
  font-family: CaviarDreams-Regular; src: url(./Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
  font-family: CaviarDreams-Bold; src: url(./Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
*{
  box-sizing: border-box;
}
.App{
  padding: 0;
  margin: 0;
}
html, body{
  background-color: #fce7e5;
}
.boton-flotante{
  display: flex;
  height: 6em;
  width: 6em;
  background-color: #125d51;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  transition: .3s;
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-color: #f7b0aa;
  border-width: 10px;
  border-style: solid;
  padding: 2%;
  color: #fce7e5;
  font-family: CaviarDreams-Bold;
  font-size: 1.5em;
  text-decoration: none;
}
.boton-flotante:hover{
  width: 8em;
  border-radius: 12vh;
  transition: .3s;
  background-color: #fce7e5;
  color: #125d51;
}
@media screen and (max-width: 480px) {
  .boton-flotante{
    width: 100px;
    height: 100px;
    font-size: 1em;
    border-width: 7px;
  }
  
}

