@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.blog-page-container{
    background-image: url("./Images/FOndo.png");
    padding: 0;
    background-size: cover;
}
.row-blog-banner-titulo{
    padding: 0;
}
.blog-banner-pc{
    padding: 0%;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-color: #f7b0aa;
    border-bottom-width: 15px;
}
.blog-banner-mobile{
    display: none;
    padding: 0%;
    width: 100%;
    border-bottom-style: solid;
    border-bottom-color: #f7b0aa;
    border-bottom-width: 15px;
}
.div-color-layer{
    background-color: #125d5100;
    border-radius: 3rem;
    width: 100%;
    height: 100%;
    transition: 1s;
}
.color-layer-hover-text{
    color: #fce7e5;
    font-family: CaviarDreams-Bold;
    font-size: 1.5rem;
    position: absolute;
    bottom: 45%;
    left: 38%;
    opacity: 0;
    text-decoration: underline;
    text-align: center;
    z-index: 10;
}
.div-color-layer:hover .color-layer-hover-text{
    opacity: 1;
    transition: 1s;
}
.div-color-layer:hover {
    background-color: #125d51;
    border-radius: 3rem;
    transition: 1s;
}
.div-color-layer:hover .div-img-blog-item-card{
    transition: 1s;
    opacity: 0.3;
}
.div-color-layer:hover .body-blog-item-card{
    transition: 1s;
    opacity: 0.3;
}
.row-blog-page-titulo{
    text-align: center;
    font-family: CaviarDreams-Bold;
    padding-top:  4%;
    color: #125d51;
}
.row-blog-items-container{
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 0% 2% 0% 2%;
}
.col-blog-item-card{
    background-color: #74B795;
    margin: 2%;
    border-radius: 3rem;
    transition: 1s;
}
.col-blog-item-card:hover {
    transform: scale(1.08) ;
    transition: 1s;
}
.col-blog-item-card a{
    text-decoration: none !important;
}
.div-img-blog-item-card{
    overflow: hidden;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    height: 50%;
}
.img-blog-item-card{
    width: 100%;
}
.body-blog-item-card{
    padding: 2% 5% 0% 5%;
    color: #fce7e5;
}
.body-blog-item-card h4{
    font-family: CaviarDreams-Bold;
    color: #fce7e5;
}
.body-blog-item-card h6{
    font-family: CaviarDreams-Bold;
    color: #fce7e5;
}
.body-blog-item-card p{
    font-family: CaviarDreams-Regular;
    color: #fce7e5;
    padding: 0% 0% 2% 0%;
    text-align: justify;
}
.ver-mas{
    color: #fce7e5 ;

}
@media screen and (max-width: 480px) {
    .blog-banner-pc{
        display: none;
    }
    .blog-banner-mobile{
        display: block;
    }
    .col-blog-item-card:hover {
        transform: scale(1.02) ;
        transition: 1s;
    }
}
