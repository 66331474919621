@font-face {
    font-family: CaviarDreams-Regular; src: url(../Fonts/CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(../Fonts/CaviarDreams_Bold.ttf) format("truetype");
}
.carousel-indicators [data-bs-target]{
    border-radius: 100%;
    height: 10px;
    width: 10px;
}
.carousel-item{
    background-image: url('./images/fondospecialistaslineacontinua.png');
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
}
.row-carousel1{
    height: fit-content;
    width: 100%;
    margin: 0;
    --bs-gutter-x:0;
    --bs-gutter-y:0;
    background-image: url("./images/fondospecialistaslineacontinua.png") ;
    background-size: 100%;
}
.carousel-especialistas{
    width: 80%;
    height: fit-content;
    padding-top: 5%;
    padding-bottom: 5%;
    margin: auto;
    
}
.react-multi-carousel-dot-list .react-multi-carousel-dot{
    padding-bottom: 2%;
    padding-left:0.6%;
    padding-right: 0.6%;
}
.carousel-especialistas .react-multi-carousel-dot-list .react-multi-carousel-dot button{
    width: 20px;
    height: 20px;
    background: #61987c ;
    border-width: 3px;
    border-color: #125d51;
    
}
.carousel-especialistas .react-multi-carousel-dot-list .react-multi-carousel-dot--active button{
    background: #125d51 ;
}
.carousel-servicios .react-multi-carousel-dot-list .react-multi-carousel-dot button{
    width: 20px;
    height: 20px;
    background: #fce7e5 ;
    border-width: 3px;
    border-color: #74B795;
}
.carousel-servicios .react-multi-carousel-dot-list .react-multi-carousel-dot--active button{
    background: #74B795 ;
}
.react-multiple-carousel__arrow--left{
    left: auto !important;
    opacity: 40%!important;
    border-radius: 50% !important; 
}
.react-multiple-carousel__arrow--right{
    right: calc(0% + 0px) !important;
    opacity: 40%!important;
    border-radius: 50% !important;
}

.row-carousel2{
    width: 100%;
    height: fit-content;
    margin: 0;
    background-image: url('./images/fondo-servicios-home.png');
    background-size: 100%;
}
.carousel-servicios{
    display: flex;
    width: 80%;
    height: fit-content;
    padding-top: 2%;
    padding-bottom: 2%;
    margin: auto;
    align-items: stretch;
}
.row-carousel-servicios-item-panel{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.col-texto-carousel-servicios{
    justify-content: center;
    align-items: center;
    padding: 5%;
    text-align: center;
    font-family: CaviarDreams-Bold;
    color: #125d51;
    font-size: clamp(0.9rem, 1.5vw, 1.4rem);
}
.col-img-carousel-servicios{
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.img-carousel-servicios{
    width: 99%;
}
@media screen and (max-width: 480px) {
    .carousel-especialistas{
        width: 100%;
    }
    .carousel-servicios{
        width: 100%;
    }
    .col-texto-carousel-servicios{
        padding: 0;
    }
    .col-img-carousel-servicios{
        padding: 0;
    }
    .img-carousel-servicios{
        width: 100%;
    }
    .row-carousel-servicios-item-panel{
        padding: 5%;
    }
}
@media screen and (max-width: 991px) {
    .carousel-especialistas{
        width: 100%;
    }
    .carousel-servicios{
        width: 100%;
    }
    .col-texto-carousel-servicios{
        padding: 0;
    }
    .col-img-carousel-servicios{
        padding: 0;
    }
    .img-carousel-servicios{
        width: 100%;
    }
    .row-carousel-servicios-item-panel{
        padding: 5%;
    }
}