@font-face {
    font-family: CaviarDreams-Regular; src: url(./CaviarDreams.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Bold; src: url(./CaviarDreams_Bold.ttf) format("truetype");   
}
@font-face {
    font-family: CaviarDreams-BoldItalic; src: url(./CaviarDreams_BoldItalic.ttf) format("truetype");
}
@font-face {
    font-family: CaviarDreams-Italic; src: url(./CaviarDreams_Italic.ttf) format("truetype");
}
